import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Flex, Image, Stepper } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import ChaiWhiteLogo from '../../../../assets/chai-logo-inverted.svg';
import ChaiDarkLogo from '../../../../assets/chai-logo.png';
import StepperIcon from 'assets/icons/stepper.svg';
import styles from './styles.module.scss';

const Header = (props) => {
  const { showLeftPanel, active, setActive, logout } = props;
  const screen767 = useMediaQuery('(max-width: 767px)');
  const stepClasses = {
    0: styles.contentIntroduction,
    1: styles.contentConnections,
    2: styles.contentAddSource,
    3: styles.contentAskQuestion
  };
  return (
    <Flex
      className={classNames(styles.wrapper, {
        [styles.askAQuestionStyles]: active === 3,
        [styles.paddingFix]: active < 3
      })}
    >
      <Image
        src={showLeftPanel ? ChaiWhiteLogo : ChaiDarkLogo}
        w={120}
        className={classNames(styles.albusLogo, { [styles.hidden]: active === 3 })}
      />
      <Flex>
        <Stepper
          classNames={{
            root: styles.stepperRoot,
            content: stepClasses[active],
            separator: styles.separator,
            stepIcon: styles.stepIcon
          }}
          className={classNames(showLeftPanel && styles.stepperCenter, {
            [styles.hidden]: screen767 && active === 3
          })}
          active={active}
          onStepClick={setActive}
        >
          <Stepper.Step allowStepSelect={false} icon={<img src={StepperIcon} />}>
            Introduction
          </Stepper.Step>
          <Stepper.Step allowStepSelect={false} icon={<img src={StepperIcon} />}>
            Connect app
          </Stepper.Step>
          <Stepper.Step allowStepSelect={false} icon={<img src={StepperIcon} />}>
            Add sources
          </Stepper.Step>
          <Stepper.Step allowStepSelect={false} icon={<img src={StepperIcon} />}>
            Ask question
          </Stepper.Step>
        </Stepper>
      </Flex>
      <Button
        onClick={logout}
        className={classNames(styles.signOutBtn, { [styles.hidden]: active === 3 })}
      >
        Sign out
      </Button>
    </Flex>
  );
};

Header.defaultProps = {
  showLeftPanel: false,
  logout: () => null
};

Header.propTypes = {
  showLeftPanel: PropTypes.bool,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired,
  logout: PropTypes.func
};

export default Header;

import { ActionIcon, Badge, Button, Flex, Image, Text } from '@mantine/core';
import { bool, func, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import ProfilePicture from 'components/ProfilePicture';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { ReactComponent as NoPreviewIllustration } from 'assets/widgets/incomplete-setup.svg';
import { PERSONA_ICON } from '../constants';
import styles from './styles.module.scss';
import * as emoji from 'node-emoji';

const PersonaDetails = ({ persona, selected, handleSelectBtnClick, handleCrossClick }) => {
  const { user } = useSelector((state) => state.user);
  const isGlobal = persona.scope === 'global';

  return (
    <div className={styles.overlay}>
      <Flex className={styles.wrapper}>
        <div className={styles.mobileDetailsWrapper}>
          {/* only visible for mobile view */}
          <Flex className={styles.headingContainer}>
            <Text className={styles.heading}>Persona</Text>
            <ActionIcon variant='transparent' onClick={handleCrossClick}>
              <SVGIcon name={ICONS_LIST.CROSS} height={18} width={18} />
            </ActionIcon>
          </Flex>
          <Flex className={styles.highlightWrapper}>
            <Flex className={styles.titleContainer}>
              <Image classNames={{ root: styles.icon }} src={persona.icon} alt={persona.name} />
              <Text className={styles.title}>{persona.name}</Text>
              {selected && (
                <Badge className={styles.badge} variant='outline'>
                  Selected
                </Badge>
              )}
            </Flex>
            {!selected && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectBtnClick(persona._id);
                }}
                className={classNames(styles.button)}
              >
                Select
              </Button>
            )}
          </Flex>
        </div>
        <div className={styles.rightSection}>
          <Flex className={styles.detailsWrapper}>
            <Text className={styles.label}>
              <b>Definition</b>
            </Text>
            <Text className={styles.details}>{isGlobal ? persona.details : persona.prompt}</Text>
          </Flex>
          {persona?.dynamicViews?.loading?.emoji && (
            <Flex className={styles.detailsWrapper}>
              <Text className={styles.label}>
                <b>Emoji</b>
              </Text>
              <Text className={styles.details}>
                {emoji.emojify(`:${persona?.dynamicViews?.loading?.emoji}:` || '')}
              </Text>
            </Flex>
          )}
          {persona?.dynamicViews?.loading?.message && (
            <Flex className={styles.detailsWrapper}>
              <Text className={styles.label}>
                <b>Fallback Response</b>
              </Text>
              <Text className={styles.details}>{persona?.dynamicViews?.loading?.message}</Text>
            </Flex>
          )}
          <Flex className={styles.answerPreviewWrapper}>
            <Text className={styles.label}>
              <b>Preview</b>
            </Text>
            {isGlobal ? (
              <Flex direction={'column'} className={styles.answerPreviewContainer}>
                <Flex p={16} align={'center'}>
                  <ProfilePicture photoUrl={user?.photo} name={user?.name} />
                  <Text ml={18} className={styles.question}>
                    {persona.question}
                  </Text>
                </Flex>
                <Flex p={16}>
                  <Image
                    h={40}
                    w={40}
                    radius={'xl'}
                    className={styles.icon}
                    src={PERSONA_ICON[persona.icon]}
                    alt={persona.name}
                  />
                  <Text ml={18} className={styles.answer}>
                    {persona.answer}
                  </Text>
                </Flex>
              </Flex>
            ) : (
              <Flex direction='column' align='center' justify='center' w={'100%'} gap={8}>
                <NoPreviewIllustration height={144} />
                <Text ta='center' size='sm'>
                  Custom personas cannot be previewed.
                </Text>
              </Flex>
            )}
          </Flex>
        </div>
      </Flex>
    </div>
  );
};

PersonaDetails.defaultProps = {
  selected: false
};

PersonaDetails.propTypes = {
  persona: shape({
    icon: string,
    name: string,
    _id: string,
    details: string,
    question: string,
    answer: string
  }).isRequired,
  selected: bool,
  handleSelectBtnClick: func.isRequired,
  handleCrossClick: func.isRequired
};

export default PersonaDetails;

import { Button, Flex, Modal, Text, Title } from '@mantine/core';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal/index.jsx';
import PersonaList from './PesonaList';
import PersonaDetails from './PersonaDetails';
import CustomPersonaForm from './CustomPersonaForm';
import styles from './styles.module.scss';

const View = ({
  personasList,
  selectedPersona,
  focusedPersona,
  handleTileClick,
  handleSelectBtnClick,
  showCustomForm,
  setShowCustomForm,
  personaToEditOrDelete,
  handleEditPersonaClick,
  handleDeletePersonaClick,
  handleCustomFormClose,
  customPersonaForm,
  handleCreateOrEditPersona,
  loading,
  showDeleteConfirmation,
  handleDeleteModalClose,
  handleDeletePersona
}) => {
  return (
    <>
      <Flex className={styles.wrapper}>
        <Flex className={styles.headerWrapper}>
          <Text className={styles.heading}>Personas</Text>
          <Button
            className='primaryBtn'
            size='compact-md'
            onClick={() => {
              customPersonaForm.setValues({
                name: '',
                definition: '',
                emoji: null,
                fallbackResponse: '',
                personality: null
              });
              setShowCustomForm(true);
            }}
          >
            Create persona
          </Button>
        </Flex>
        <Flex>
          <PersonaList
            personasList={personasList}
            selectedPersona={selectedPersona}
            focusedPersona={focusedPersona}
            handleTileClick={handleTileClick}
            handleSelectBtnClick={handleSelectBtnClick}
            handleEditPersonaClick={handleEditPersonaClick}
            handleDeletePersonaClick={handleDeletePersonaClick}
          />
          {focusedPersona?._id ? (
            <PersonaDetails
              persona={focusedPersona}
              selected={focusedPersona._id === selectedPersona}
              handleCrossClick={() => handleTileClick({})}
              handleSelectBtnClick={handleSelectBtnClick}
            />
          ) : null}
        </Flex>
      </Flex>
      <Modal
        opened={showCustomForm}
        onClose={handleCustomFormClose}
        centered
        size={550}
        title={
          <Title className={styles.createEditModalTitle}>
            {personaToEditOrDelete
              ? `Edit persona - ${personaToEditOrDelete?.name}`
              : 'Create Persona'}
          </Title>
        }
      >
        <CustomPersonaForm
          personasList={personasList}
          persona={personaToEditOrDelete}
          customPersonaForm={customPersonaForm}
          handleCreateOrEditPersona={handleCreateOrEditPersona}
          handleCancel={handleCustomFormClose}
          loading={loading}
        />
      </Modal>
      <DeleteWarningModal
        open={showDeleteConfirmation}
        onClose={handleDeleteModalClose}
        handleCancel={handleDeleteModalClose}
        handleDelete={() => handleDeletePersona(personaToEditOrDelete?._id)}
        header={<Text className={styles.createEditModalTitle}>Delete persona</Text>}
        title={
          <Text className={styles.createEditModalTitle}>
            Are you sure you want to delete the persona - {personaToEditOrDelete?.name}?
          </Text>
        }
        body={
          <Text className={styles.deletePersonaModalBody}>
            <span>Note:</span> if this persona is currently selected for your company, deleting it
            would change the persona to <b>Default</b>. All your teammates will see this change when
            they receive responses from Chat Aid .
          </Text>
        }
        removeText='Delete'
        customClass={{ cancelBtn: styles.cancelBtn }}
        loading={loading}
      />
    </>
  );
};

View.propTypes = {
  personasList: arrayOf(
    shape({
      icon: string,
      name: string,
      _id: string
    })
  ).isRequired,
  selectedPersona: string.isRequired,
  focusedPersona: shape({
    icon: string,
    name: string,
    _id: string
  }).isRequired,
  personaToEditOrDelete: shape({
    icon: string,
    name: string,
    _id: string
  }),
  handleTileClick: func.isRequired,
  handleSelectBtnClick: func.isRequired,
  handleEditPersonaClick: func.isRequired,
  handleDeletePersonaClick: func.isRequired,
  showCustomForm: bool.isRequired,
  setShowCustomForm: func.isRequired,
  handleCustomFormClose: func.isRequired,
  customPersonaForm: shape({}).isRequired,
  handleCreateOrEditPersona: func.isRequired,
  loading: bool,
  showDeleteConfirmation: bool.isRequired,
  handleDeleteModalClose: func.isRequired,
  handleDeletePersona: func.isRequired
};

View.defaultProps = {
  loading: false,
  personaToEditOrDelete: null
};

export default View;

import { node } from 'prop-types';
import ChaiLightLogo from 'assets/chai-logo.png';
import { Image } from '@mantine/core';
import styles from './styles.module.scss';

const AuthWrapper = ({ children }) => {
  return (
    <div className={styles.auth}>
      <Image w={'15rem'} h={'100%'} src={ChaiLightLogo} />
      <div className={styles.content}>{children}</div>
    </div>
  );
};

AuthWrapper.propTypes = {
  children: node.isRequired
};

export default AuthWrapper;

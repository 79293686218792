import PropTypes from 'prop-types';
import JoinedCompanies from './JoinedCompanies';
import OtherCompanies from './OtherCompanies';
import ChangeEmail from './ChangeEmail';
import ChaiLightLogo from 'assets/chai-logo.png';
import styles from './styles.module.scss';
import { Image } from '@mantine/core';

const ChooseCompany = (props) => {
  const { companiesData, handleCreateCompany } = props;

  return (
    <div className={styles.auth}>
      <Image w={'15rem'} h={'100%'} src={ChaiLightLogo} />
      {companiesData.userJoinedOrgs.length > 0 && (
        <JoinedCompanies companiesList={companiesData.userJoinedOrgs} />
      )}

      {companiesData.userInvitedOrgs.length > 0 && (
        <OtherCompanies
          companiesList={companiesData.userInvitedOrgs}
          type='invited'
          companyDomain={companiesData.domain}
        />
      )}

      {companiesData.sameDomainOrgs.length > 0 && (
        <OtherCompanies
          companiesList={companiesData.sameDomainOrgs}
          type='sameDomain'
          companyDomain={companiesData.domain}
        />
      )}

      <ChangeEmail
        handleCreateCompany={handleCreateCompany}
        showCreateCompany={companiesData.canCreateOrg}
      />
    </div>
  );
};

ChooseCompany.defaultProps = {
  companiesData: {}
};

ChooseCompany.propTypes = {
  companiesData: PropTypes.object,
  handleCreateCompany: PropTypes.func.isRequired,
  handleJoin: PropTypes.func.isRequired
};

export default ChooseCompany;

import React, { useEffect, useState } from 'react';
import { useGetSettingsQuery } from 'redux/services/settings';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Avatar, Image, Tooltip, createTheme, MantineThemeProvider, Button } from '@mantine/core';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import SearchAnswerIcon from './SearchAnswerIcon/View';
import { returnOtherIntegrationsIcon } from 'utils/utilities';
import { capitalizeFirstLetter, capitalizeInitials, convertPotentialUrls } from 'utils/formatters';
import { emojiFy } from 'utils/helpers';
import ChaiLogo from 'assets/icons/chai-circle.svg';
import ArrowDownIcon from 'assets/ask-chai/arrow-down.svg';
import ThumbsUpIcon from 'assets/ask-chai/thumbs-up-icon.svg';
import CopyIcon from 'assets/ask-chai/copy-icon.svg';
import InfoIcon from 'assets/ask-chai/info-icon.svg';
import SwitchIcon from 'assets/ask-chai/switch-icon.svg';
import Upvoted from 'assets/ask-chai/upvoted.svg';
import { NAVBAR_ROUTES, QUERY_ROUTING, LATEST_MODEL_UPDATE_MONTH } from 'utils/constants';
import styles from './styles.module.scss';

const theme = createTheme({
  components: {
    Tooltip: Tooltip.extend({
      defaultProps: {
        withArrow: true,
        multiline: false,
        arrowPosition: 'center',
        position: 'top-start',
        color: 'grayscale.2'
      },
      classNames: {
        root: 'tooltip-custom'
      }
    })
  }
});

const View = (props) => {
  const {
    prompt,
    role,
    sourcesUsed,
    hasOtherAnswers,
    upvoted,
    downvoted,
    handleVote,
    completion,
    loading,
    streaming,
    generateAnotherAnswer,
    showAiAnswer,
    setShowAiAnswer,
    completedUsing,
    canPoll,
    handleSearchAnswerClick,
    llmWiki,
    wikiAnswerFound,
    queryRoutingCategory
  } = props;
  const { user } = useSelector((state) => state.user);
  const { planInfo } = useSelector((state) => state.plan);
  const { data: settings } = useGetSettingsQuery();
  const loadingMessage = settings?.personas.find(
    (persona) => persona._id === settings.selectedPersona
  )?.message;

  const [sourcesToShow, setSourcesToShow] = useState(sourcesUsed.slice(0, 3));
  const [hideSources, setHideSources] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    handleSourcesShown();
  }, [sourcesUsed]);

  function handleSourcesShown() {
    if (hideSources) {
      setSourcesToShow(sourcesUsed);
      setHideSources(false);
      return;
    }
    setSourcesToShow(sourcesUsed.slice(0, 3));
    setHideSources(true);
  }

  const format = (emoji) => {
    if (emoji === ':magic_wand:') {
      return '🪄'; // Fallback for :magic_wand:
    } else {
      return emoji; // default emoji for others
    }
  };

  function generateWikiAnswerTitle() {
    let message = user?.customViews?.completions?.wikiHeader || 'Wiki search result:';

    if (llmWiki) {
      const { teams, connectors, sources } = llmWiki;
      if (!teams.length && !connectors.length && !sources.length) {
        return (
          user?.customViews?.completions?.wikiFiltersNotFoundHeader ||
          'I could not identify any search filters in your message, so I searched across all your wiki data:'
        );
      }

      const formattedTeams = teams.map(capitalizeFirstLetter);
      const formattedConnectors = connectors.map(capitalizeFirstLetter);
      const formattedSources = sources.map(capitalizeFirstLetter);

      message = (
        <>
          <span>I identified these search filters in your message:</span>
          <ul>
            {teams.length > 0 && (
              <li>
                Teams:&nbsp;
                <span className={styles.filterCategoryItems}>{formattedTeams.join(', ')}</span>
              </li>
            )}
            {connectors.length > 0 && (
              <li>
                Connectors:&nbsp;
                <span className={styles.filterCategoryItems}>{formattedConnectors.join(', ')}</span>
              </li>
            )}
            {sources.length > 0 && (
              <li>
                Sources:&nbsp;
                <span className={styles.filterCategoryItems}>{formattedSources.join(', ')}</span>
              </li>
            )}
          </ul>
          <span>
            {user?.customViews?.completions?.wikiFiltersFoundHeader ||
              'According to your filtered wiki data:'}
          </span>
        </>
      );
    }

    return message;
  }

  function generateAiAnswerTitle() {
    const tooltipWithIcon = (
      <>
        &nbsp;
        <Tooltip
          label={`I use ChatGPT to search for an answer online. This means my answers are based on information up to ${LATEST_MODEL_UPDATE_MONTH}.`}
          multiline
          w={274}
        >
          <Image src={InfoIcon} w={16} />
        </Tooltip>
        &nbsp;
      </>
    );

    let title = user?.customViews?.completions?.aiHeader ? (
      <>
        {user?.customViews?.completions?.aiHeader}
        {tooltipWithIcon}
      </>
    ) : (
      <>
        Online search result
        {tooltipWithIcon}:
      </>
    );

    if (queryRoutingCategory === QUERY_ROUTING.IMAGE) {
      title = 'Here’s the image generated based on your request.';
    }

    return title;
  }

  function getMessageText() {
    if (role === 'user') {
      return prompt;
    }
    if (loading || canPoll) {
      return (
        loadingMessage || settings?.loading?.message || "I'm on it! Looking for an answer now..."
      );
    }

    const fallbackMessage =
      user?.customViews?.completions?.fallback ||
      'Chai was not able to find an answer for this question';

    //Image markdown format for ImageGeneration category
    if (queryRoutingCategory === QUERY_ROUTING.IMAGE) {
      return `![Image](${completion.ai})`;
    }

    if (hasOtherAnswers) {
      if (showAiAnswer) {
        return completion.ai !== '' ? completion.ai : fallbackMessage;
      }
      return completion.training !== '' ? completion.training : fallbackMessage;
    }

    return completion[completedUsing] !== '' && completion[completedUsing] !== undefined
      ? completion[completedUsing]
      : fallbackMessage;
  }

  function copyResponseToClipboard() {
    navigator.clipboard.writeText(getMessageText());
    toast.success('Copied!');
  }

  function handleSourceClick(url) {
    if (!url) {
      return;
    }
    window.open(url, '_black');
  }

  const showCreateWiki =
    !loading &&
    !canPoll &&
    (user?.isAdmin || user?.isInstaller || user?.isTeamAdmin) &&
    user?.isNotFullySetup &&
    role === 'assistant';

  return (
    <MantineThemeProvider theme={theme}>
      <div className={styles.messageContainer}>
        {/* Left Section */}
        <Avatar
          src={role === 'user' ? user.photo || user.name : ChaiLogo}
          alt="it's me"
          size={'md'}
          radius='xl'
        >
          {role === 'user' && !user?.photo && capitalizeInitials(user?.name)}
        </Avatar>

        {/* Right Section */}
        <div
          className={classNames(styles.messageText, {
            [styles.assitantMessageText]: role === 'assistant'
          })}
        >
          {/* Switch to toggle ai and wiki answer */}
          {hasOtherAnswers && role === 'assistant' && (
            <div className={styles.switchButton} onClick={() => setShowAiAnswer((prev) => !prev)}>
              Switch to {showAiAnswer ? 'wiki' : 'online'} answer
              <Image src={SwitchIcon} w={13} />
            </div>
          )}

          {/* Title showing the answer is from ai or wiki */}
          {role === 'assistant' && !showAiAnswer && !loading && !streaming && !canPoll && (
            <b className={styles.completedUsingWikiText}>
              {generateWikiAnswerTitle()}
              <br />
            </b>
          )}
          {role === 'assistant' && showAiAnswer && !loading && !streaming && !canPoll && (
            <b className={styles.completedUsingAiText}>
              {generateAiAnswerTitle()}
              <br />
            </b>
          )}

          {/* Message/Prompt/Albus Reply */}
          <div
            className={classNames(styles.messageArrow, {
              [styles.assistantMessageArrow]: role === 'assistant'
            })}
          />
          <ReactMarkdown
            className={classNames(styles.reactMarkdown, {
              [styles.messageOverflow]: role === 'assistant'
            })}
            components={{
              a: ({ ...props }) => <a target='_blank' {...props} />
            }}
          >
            {convertPotentialUrls(emojiFy(getMessageText()), { format })}
          </ReactMarkdown>

          {showCreateWiki && (
            <Button
              onClick={() => navigate(NAVBAR_ROUTES.INTEGRATIONS)}
              classNames={{ root: styles.createWikiButton, inner: styles.createWikiButtonInner }}
            >
              📖 Create wiki
            </Button>
          )}

          {/* Cited Sources */}
          {sourcesUsed.length > 0 && role === 'assistant' && !showAiAnswer && (
            <div className={styles.citedSourcesWrapper}>
              <div className={styles.sourcesText}>Sources:&nbsp;</div>
              <div className={styles.citedSources}>
                {sourcesToShow.map((source) => (
                  <div
                    className={classNames(styles.citedSourceTag, {
                      [styles.sourceWithUrl]: source.url || source.publicUrl
                    })}
                    key={source.name}
                    onClick={() => handleSourceClick(source.url || source.publicUrl)}
                  >
                    <Image src={returnOtherIntegrationsIcon(source.provider)} w={14} />
                    <div className={styles.citedSourceName}>
                      {source.provider === 'slack' && '#'}
                      {source.name}
                    </div>
                  </div>
                ))}
                {sourcesUsed.length > 3 && (
                  <div className={styles.showMoreSourcesText} onClick={handleSourcesShown}>
                    {hideSources ? (
                      <>
                        +{sourcesUsed.length - 3} other sources
                        <Image src={ArrowDownIcon} w={16} className={styles.arrowDownIcon} />
                      </>
                    ) : (
                      <>
                        Show less
                        <Image src={ArrowDownIcon} w={16} className={styles.arrowUpIcon} />
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Action Buttons to upvote, downvote, copy and generate ai or wiki answer */}
          {role === 'assistant' && !streaming && !loading && !canPoll && (
            <div className={classNames(styles.actionButtons)}>
              {!(completedUsing === 'training' && !wikiAnswerFound) && (
                <>
                  <Tooltip label='Upvote'>
                    <div className={styles.actionIcon} onClick={() => handleVote('upvote')}>
                      <Image src={upvoted ? Upvoted : ThumbsUpIcon} w={16} />
                    </div>
                  </Tooltip>
                  <Tooltip label='Downvote'>
                    <div className={styles.actionIcon} onClick={() => handleVote('downvote')}>
                      <Image
                        src={downvoted ? Upvoted : ThumbsUpIcon}
                        w={16}
                        className={styles.invertedImage}
                      />
                    </div>
                  </Tooltip>
                </>
              )}
              <Tooltip label='Copy'>
                <div className={styles.actionIcon} onClick={copyResponseToClipboard}>
                  <Image src={CopyIcon} w={16} />
                </div>
              </Tooltip>
              {!hasOtherAnswers && queryRoutingCategory != QUERY_ROUTING.IMAGE && (
                <SearchAnswerIcon
                  showAiAnswer={showAiAnswer}
                  isFreePlan={!planInfo.hasSubscription}
                  isAdmin={user?.isAdmin}
                  generateAnotherAnswer={generateAnotherAnswer}
                  handleSearchAnswerClick={handleSearchAnswerClick}
                  customViews={user?.customViews}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </MantineThemeProvider>
  );
};

View.propTypes = {
  prompt: PropTypes.string,
  completion: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      ai: PropTypes.string,
      training: PropTypes.string
    })
  ]),
  role: PropTypes.string.isRequired,
  sourcesUsed: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      provider: PropTypes.string.isRequired,
      url: PropTypes.string
    })
  ),
  hasOtherAnswers: PropTypes.bool,
  upvoted: PropTypes.bool,
  downvoted: PropTypes.bool,
  handleVote: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  streaming: PropTypes.bool,
  generateAnotherAnswer: PropTypes.func.isRequired,
  showAiAnswer: PropTypes.bool.isRequired,
  setShowAiAnswer: PropTypes.func.isRequired,
  completedUsing: PropTypes.string.isRequired,
  canPoll: PropTypes.bool,
  handleSearchAnswerClick: PropTypes.func.isRequired,
  llmWiki: PropTypes.object.isRequired,
  wikiAnswerFound: PropTypes.bool,
  queryRoutingCategory: PropTypes.string
};

View.defaultProps = {
  avatar: null,
  sourcesUsed: [],
  hasOtherAnswers: false,
  loading: false,
  streaming: false,
  canPoll: false,
  wikiAnswerFound: true,
  queryRoutingCategory: ''
};

export default View;

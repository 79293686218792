import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ActionIcon,
  Button,
  Flex,
  Group,
  Menu,
  Textarea,
  TextInput,
  Input,
  CloseButton,
  Select
} from '@mantine/core';
import classnames from 'classnames';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from './styles.module.scss';
import EmojiPicker from 'emoji-picker-react';
import * as emoji from 'node-emoji';

const getPersonaOption = (personasList, persona = null) => {
  let optionsData = personasList
    .filter((personas) => personas?.scope === 'global')
    .map((personas) => ({ value: personas._id, label: personas.name }));
  if (persona) {
    optionsData.push({ value: persona?._id, label: persona?.name });
  } else {
    optionsData.push({ value: '0', label: 'Custom Persona' });
  }

  return optionsData;
};

const setSelectedOptions = (personasList, persona) => {
  let selectedPersonality = getPersonaOption(personasList)[0];
  if (persona) {
    const options = getPersonaOption(personasList, persona);
    return options[options.length - 1];
  }
  return selectedPersonality;
};

const getDefinition = (personasList, value) => {
  const selectedPersona = personasList.find((persona) => persona._id === value);
  return selectedPersona?.scope === 'global' ? selectedPersona?.details : selectedPersona?.prompt;
};

const CustomPersonaForm = (props) => {
  const {
    personasList,
    persona,
    customPersonaForm,
    handleCreateOrEditPersona,
    handleCancel,
    loading
  } = props;
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [emojiValue, setEmojiValue] = useState(() => {
    const emojiName = customPersonaForm.getInputProps('emoji').value?.name;
    return persona && emojiName ? emoji.emojify(`:${emojiName}:`) : '';
  });
  const [selectedPersonality, setSelectedPersonality] = useState(
    setSelectedOptions(personasList, persona)
  );

  useEffect(() => {
    if (!persona) {
      customPersonaForm.setFieldValue(
        'definition',
        getDefinition(personasList, selectedPersonality.value)
      );
      if (selectedPersonality?.value === '0') {
        customPersonaForm.setFieldValue('definition', '');
      }
    } else {
      if (customPersonaForm.getInputProps('definition').value === '') {
        customPersonaForm.setFieldValue('definition', persona.prompt);
      }
    }

    customPersonaForm.setFieldValue('personality', selectedPersonality);
  }, [selectedPersonality]);

  return (
    <form
      onSubmit={customPersonaForm.onSubmit((values) => {
        return handleCreateOrEditPersona(values);
      })}
    >
      <TextInput
        label='Name'
        description='Name your persona'
        placeholder='Chai'
        {...customPersonaForm.getInputProps('name')}
        mb={12}
      />
      <Input.Wrapper
        label='Response Emoji (optional)'
        description='The emoji reaction to use when searching for an answer. Leave Blank to respond with a message instead.'
        mb={12}
        fluid
      >
        <Flex>
          <Button
            disabled={loading}
            className='primaryBtn '
            type='button'
            my={6}
            w={'25%'}
            onClick={() => setIsEmojiPickerOpen(!isEmojiPickerOpen)}
          >
            Select Emoji
          </Button>

          <Input
            placeholder='Select Emoji / write your custom emoji name'
            value={emojiValue}
            onChange={(event) => {
              setEmojiValue(event.currentTarget.value);
              customPersonaForm.setFieldValue('emoji', {
                name: event.currentTarget.value
              });
            }}
            w={'65%'}
            mt={10}
            ml={5}
          />
          <CloseButton
            aria-label='Clear input'
            mt={12}
            onClick={() => setEmojiValue('')}
            style={{ display: emojiValue ? undefined : 'none' }}
          />
        </Flex>
        <span style={{ height: 100, position: 'absolute', zIndex: 1 }}>
          <EmojiPicker
            open={isEmojiPickerOpen}
            onEmojiClick={(value) => {
              setIsEmojiPickerOpen(false);
              setEmojiValue(value?.emoji);
              customPersonaForm.setFieldValue('emoji', {
                name: value?.names[0],
                emoji: value?.emoji
              });
            }}
          />
        </span>
      </Input.Wrapper>
      <TextInput
        label='Fallback Response Message'
        description='As a fallback when emoji reactions are unavailable, let the user know you are searching for an answer with this response.'
        placeholder="Example: I'm on it! Looking for an answer now..."
        {...customPersonaForm.getInputProps('fallbackResponse')}
        mb={12}
      />

      <Select
        label='Personality'
        description='Define the personality, voice and behavior when responding.'
        placeholder='Pick value'
        data={getPersonaOption(personasList, persona)}
        defaultValue={setSelectedOptions(personasList, persona).value}
        onChange={(_value, option) => {
          setSelectedPersonality(option), customPersonaForm.setFieldValue('personality', option);
          const definition = getDefinition(personasList, _value);
          customPersonaForm.setFieldValue('definition', definition);
        }}
        mb={12}
        allowDeselect={false}
      />
      <Textarea
        label='Definition'
        description='Define Chai’s personality, voice and behavior when he responds to teammates.'
        placeholder='Be precise, write short responses and keep a formal tone in your language.'
        disabled={
          persona ? persona?._id !== selectedPersonality?.value : selectedPersonality?.value !== '0'
        }
        {...customPersonaForm.getInputProps('definition')}
        classNames={{
          input: styles.textArea
        }}
      />
      <Flex justify='flex-end' gap={8} align='center' mt={24}>
        <Button className='primaryBtnOutlineV2' onClick={handleCancel}>
          Cancel
        </Button>
        <Group wrap='nowrap' gap={0}>
          <Button
            disabled={loading}
            className={classnames('primaryBtn', styles.button)}
            type='submit'
          >
            {persona ? 'Save' : 'Create'}
          </Button>
          <Menu transitionProps={{ transition: 'pop' }} position='bottom-end' withinPortal>
            <Menu.Target>
              <ActionIcon
                variant='filled'
                size={36}
                className={styles.menuControl}
                loading={loading}
              >
                <SVGIcon name={ICONS_LIST.DOWN_ARROW} height={14} width={14} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() => {
                  customPersonaForm.validate();
                  if (customPersonaForm.isValid()) {
                    handleCreateOrEditPersona({ ...customPersonaForm.values, activate: true });
                  }
                }}
              >
                {persona ? 'Save and select persona' : 'Create and select persona'}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Flex>
    </form>
  );
};

CustomPersonaForm.propTypes = {
  personasList: PropTypes.arrayOf(PropTypes.shape({})),
  persona: PropTypes.shape({
    loadingState: PropTypes.shape({
      message: PropTypes.string
    }),
    _id: PropTypes.string,
    prompt: PropTypes.string
  }),
  customPersonaForm: PropTypes.shape({
    onSubmit: PropTypes.func,
    getInputProps: PropTypes.func,
    isValid: PropTypes.func,
    validate: PropTypes.func,
    values: PropTypes.shape({}),
    setFieldValue: PropTypes.func
  }).isRequired,
  handleCreateOrEditPersona: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

CustomPersonaForm.defaultProps = {
  persona: null,
  loading: false
};

export default CustomPersonaForm;

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { PasswordInput, Progress, Text, Popover, Box } from '@mantine/core';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from './styles.module.scss';

const PasswordRequirement = ({ meets, label }) => {
  return (
    <Text c={meets ? 'teal' : 'red'} className={styles.text}>
      {meets ? (
        <SVGIcon name={ICONS_LIST.CHECK_GREEN} height={9} width={9} />
      ) : (
        <SVGIcon name={ICONS_LIST.CROSS_RED} height={9} width={9} />
      )}
      <Box ml={10}>{label}</Box>
    </Text>
  );
};

PasswordRequirement.propTypes = {
  meets: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
};

const requirements = [
  { re: /[0-9]/, label: 'Includes number' },
  { re: /[a-z]/, label: 'Includes lowercase letter' },
  { re: /[A-Z]/, label: 'Includes uppercase letter' },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' }
];

const getStrength = (password) => {
  if (!password) return 0;
  let multiplier = password.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
};

const Password = (props) => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [value, setValue] = useState(props?.value || '');

  useEffect(() => {
    if (props?.value !== value) {
      setValue(props.value);
    }
  }, [props.value, value]);

  useEffect(() => {
    const allRequirementsMet = requirements.every((requirement) => requirement.re.test(value));
    const isPasswordValid = value.length > 7 && allRequirementsMet;
    props.onPasswordChange(isPasswordValid);
  }, [value, props]);

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(value)} />
  ));

  const strength = getStrength(value);
  const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';
  return (
    <Box>
      <Popover
        opened={popoverOpened}
        position='bottom'
        width='target'
        transitionProps={{ transition: 'pop' }}
      >
        <Popover.Target>
          <div
            onFocusCapture={() => setPopoverOpened(true)}
            onBlurCapture={() => setPopoverOpened(false)}
          >
            <PasswordInput
              value={value}
              onChange={(event) => setValue(event.currentTarget.value)}
              {...props}
            />
          </div>
        </Popover.Target>
        <Popover.Dropdown>
          <Progress color={color} value={strength} size={5} mb='xs' />
          <PasswordRequirement label='Includes at least 12 characters' meets={value.length > 11} />
          {checks}
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
};

Password.defaultProps = {
  ...PasswordInput.defaultProps,
  onPasswordChange: () => {}
};

Password.propTypes = {
  ...PasswordInput.propTypes,
  onPasswordChange: PropTypes.func
};

export default Password;
